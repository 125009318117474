import { getItem, postItem, putItem } from '../../../api/api-methods';
const emptyPagination = {
  currentPage: 1,
  lastPage: 1,
  previousPage: 1,
  nextPage: 1,
  offset: 0,
  limit: 5,
  count: 0,
};

export const postTutor = async (tutorObj) => {
  try {
    const URL = '/app-personas/tutor';
    const res = await postItem(URL, tutorObj);
    return { res, ok: true };
  } catch (error) {
    console.error('Error al intentar crear tutor.', error);
  }
};
export const putTutor = async (tutorObj, tutorId) => {
  try {
    const URL = '/app-personas/tutor/';
    const res = await putItem(`${URL}${tutorId}`, tutorObj);
    return { res, ok: true };
  } catch (error) {
    console.error('Error al intentar actualizar tutor.', error);
    return { ok: false, message: error?.toString() };
  }
};

export const fetchPostRegisterTutor = async (tutorObj) => {
  try {
    const URL = '/app-personas/helpers/registrar-tutor';
    const res = await postItem(URL, tutorObj);
    return { res, ok: true };
  } catch (error) {
    console.error('Error al intentar actualizar tutor.', error);
    return { ok: false, message: error?.toString() };
  }
};

export const buildStudentPersonalInfo = (currentStudent) => {
  const { ...restPersonalInfo } = currentStudent.datos_personales;
  const studentPersonalInfo = {
    ...restPersonalInfo,
    genero: currentStudent.datos_personales.genero,
    numero_credencial_alumno: currentStudent.numero_credencial_alumno,
    numero_identidad_CURP:
      currentStudent.datos_personales.numero_identidad_CURP,
    dateRange: { from: null, to: null },
    id_institucion_educativa: currentStudent.institucion_educativa.id,
    colegiatura: { ...currentStudent.colegiatura },
    grupo: currentStudent.groupId,
    nivel_educativo: currentStudent.nivel_educativo
      ? currentStudent.nivel_educativo.id
      : null,
  };
  // console.log('buildStudentPersonalInfo ', studentPersonalInfo);

  return studentPersonalInfo;
};

export const buildStudentAddress = async (studentAddressId) => {
  const studentAddress = await getItem(
    `/app-personas/direccion/${studentAddressId}`
  );
  // console.log('buildStudentAddress studentAddress', studentAddress);

  return {
    ...studentAddress,
    codigo_postal: studentAddress.codigo_postal.id,
    code: studentAddress.codigo_postal.codigo_postal,
    municipio: studentAddress.codigo_postal.nombre_municipio,
  };
};

export const buildStudentTutor = async (tutor) => {
  const { ...restPersonalInfo } = tutor.datos_personales;
  const tutorPersonalInfo = {
    ...restPersonalInfo,
    responsable_economico: tutor.responsable_economico,
    genero: tutor.datos_personales.genero,
    parentesco: tutor.parentesco,
    numero_identidad_CURP: tutor.datos_personales.numero_identidad_CURP,
  };
  // console.log('buildStudentTutor tutorPersonalInfo', tutorPersonalInfo);

  let tutorAddress = {};
  try {
    tutorAddress = await getItem(
      `/app-personas/direccion/${tutorPersonalInfo.direccion}`
    );
    tutorAddress = {
      ...tutorAddress,
      codigo_postal: tutorAddress.codigo_postal?.id || null,
      code: tutorAddress.codigo_postal?.codigo_postal || null,
      municipio: tutorAddress.codigo_postal?.nombre_municipio || null,
    };
  } catch (error) {
    console.error('Error fetching tutor address:', error);
    throw new Error('Failed to retrieve tutor address');
  }
  // console.log('buildStudentTutor tutorAddress', tutorAddress);

  const tutorTaxInfo = { ...tutor.datos_fiscales[0] };
  // console.log('buildStudentTutor tutorTaxInfo', tutorTaxInfo);
  return {
    personalInfo: tutorPersonalInfo,
    address: tutorAddress,
    taxInfo: tutorTaxInfo,
  };
};

export const findTutor = async (currentStudent) => {
  try {
    // console.log('findTutor currentStudent', currentStudent);

    const tutorId = currentStudent.tutores.length
      ? currentStudent.tutores[0].id
      : null;
    if (!tutorId) return null;
    const tutor = await getItem(`app-personas/tutor/${tutorId}`);
    // console.log('findTutor tutor', tutor);

    return tutor;
  } catch (error) {
    console.error('Error finding tutor:', error);
    return null;
  }
};

export const fetchTutorByName = async (
  name = '',
  pagination = emptyPagination,
  systemStatus = true,
  institutionId = null
) => {
  const institutionFilter =
    institutionId !== null ? `?institucion_educativa=${institutionId}` : '';
  const nameFilter =
    name !== '' && name !== null ? `&nombre_completo=${name}` : '';

  const systemStatusFilter =
    systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';

  const offsetFilter =
    pagination.offset !== 0 ? `&offset=${pagination.offset}` : '';
  const limitFilter =
    pagination.limit !== null ? `&limit=${pagination.limit}` : '';

  try {
    const URL = `/app-personas/helpers/buscar-tutor-por-nombre-completo`.concat(
      institutionFilter,
      nameFilter,
      systemStatusFilter,
      offsetFilter,
      limitFilter
    );
    const response = await getItem(`${URL}`);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo encontrar alumno por nombre. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
